// @ts-nocheck

import axios from 'axios'
import { configHeaders }  from './axiosHeaders'

import type { AxiosError, AxiosRequestHeaders, AxiosResponse, AxiosRequestConfig } from 'axios'



declare const AXIOS_BASE: string
declare const AXIOS_HEADERS: string

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
  url?: string
  method: 'get' | 'put' | 'patch' | 'post' | 'delete'
  params?: unknown
  data?: TData
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
  signal?: AbortSignal
  headers?: AxiosRequestConfig['headers']
}
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
  data: TData
  status: number
  statusText: string
  headers?: AxiosResponse['headers']
}

export const axiosInstance = axios.create({
  baseURL: typeof AXIOS_BASE !== 'undefined' ? AXIOS_BASE : undefined,
  headers: typeof AXIOS_HEADERS !== 'undefined' ? (JSON.parse(AXIOS_HEADERS) as AxiosRequestHeaders) : {"saas_id":"kiki"},
})

export const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {

  const defaultHeader = configHeaders();
  
  config.headers = {
    ...defaultHeader,
    ...config.headers
  }
  const promise = axiosInstance.request<TVariables, ResponseConfig<TData>>({...config }).catch((e: AxiosError<TError>) => {
    throw e
  })

  return promise
}

export const webBaseUrl = `${process.env.NEXT_PUBLIC_HOST_NEWAPI}v2/`
// export const webBaseUrl = 'http://localhost:3000/dev_newapi/v2/'
export const baseUrl = global?.baseUrl || webBaseUrl;

export default axiosClient
