/*
 * @Date: 2024-01-20 18:14:30
 * @LastEditors: jikang.wu jikang.wu@kikitrade.com
 * @LastEditTime: 2024-01-20 18:37:20
 */
import React, { memo } from 'react'

import Image from 'next/legacy/image';

const image = require('@images/web3/particle_logo.webp')

function ParticleLogo () {
  return (
    <div className=" flex flex-row justify-center items-center w-[220px] h-[32px] overflow-hidden">
      <Image
        src={image}
        alt="kikitrade"
        className="w-[220px] h-[32px]"
      />
    </div>
  )
}

export default memo(ParticleLogo);
