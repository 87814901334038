// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { OauthLoginMutationResponse, OauthLoginQueryParams, OauthLoginHeaderParams } from "../../models/OauthLogin";


import { baseUrl } from "../../../../axiosHooks";


export function oauthLoginMutationOptions <TData = OauthLoginMutationResponse, TError = unknown>(params: OauthLoginQueryParams, headers?: OauthLoginHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = oauthLoginQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/login/oauth`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 三方认证登录
 * @summary 三方认证登录
 * @link /customer/login/oauth
 */

export function useOauthLogin <TData = OauthLoginMutationResponse, TError = unknown>(params: OauthLoginQueryParams, headers?: OauthLoginHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...oauthLoginMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
