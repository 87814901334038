import cookie from 'js-cookie';

export function isNewUser () {
  // visitor
  const isVisitor = !(cookie?.get?.('userId') || cookie?.get?.('jwt'));

  // web3 user: no email and no phone
  let isWeb3User = false
  try {
    if (globalThis?.localStorage?.getItem('userStore')) {
      const userInfo = JSON.parse(globalThis?.localStorage?.getItem('userStore')).userInfo || {}
      // registByWallet，boolean类型，true表示是wallet注册的用户，false表示是非wallet注册的用户
      isWeb3User = (!userInfo.email && !userInfo.phone) || userInfo.registByWallet
    }
  } catch (e) {

  }

  return isVisitor || isWeb3User
}
