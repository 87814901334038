import { store } from '@store/index';
import { QueryClient } from '@tanstack/react-query';

import { OpenParticleWalletModal } from './ParticleWallet';
import { OpenWalletBindModal } from './WalletBind';

export const ConnectAdapter = async (props) => {
  const userStore: any = localStorage.getItem('userStore');
  let userInfo: any = {}
  try {
    userInfo = JSON.parse(userStore || '{}')?.userInfo
  } catch (error) {}

  const { publicKey } = userInfo;

  const loginSuccessFn = (useInfo) => {
    store.userStore.loginSuccess(useInfo);
  }

  // 2. 获取用户社交信息
  const getUserSocialInfo = (userInfo) => {
    return new Promise<void>((resolve, reject) => {
      store.userStore.getUserSocialInfo(
        { userId: userInfo.id, token: userInfo.jwtKey },
        () => {
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const successCallback = async (web3UserInfo, resolve?: any) => {
    const res = web3UserInfo
    if (!res.jwtKey) {
      res.jwtKey = userInfo?.jwtKey
    }
    await loginSuccessFn(res)
    await getUserSocialInfo(res);
    // cb?.({ res });
    // resolve(res)
  }
  const queryClient = new QueryClient();
  // console.log('userInfouserInfouserInfo', userInfo)
  // console.log('userInfouserInfouserInfo publicKey', localStorage.getItem('publicKey'))
  // console.log('userInfouserInfouserInfo store', store)

  if (!publicKey) {
    OpenWalletBindModal({
      queryClient: queryClient,
      userName: userInfo?.email || userInfo?.phone,
      userInfo: userInfo,
      callBack: async (web3UserInfo) => {
        await successCallback(web3UserInfo)
      }
    })
    return null
  }
  OpenParticleWalletModal(props)
};
