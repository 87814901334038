import React from 'react';

import Image from 'next/legacy/image';

import I18n from '@utils/i18n';

export const OSWallet = ({ image, name, id, onClick }) => {
  return (
    <button onClick={() => onClick(id)} className="flex flex-col items-center w-11 h-fit">
      <div className="rounded-3xl py-3 flex flex-row justify-center items-center w-11 h-11 overflow-hidden">
          <Image
          src={image}
          alt="kikitrade"
          className="rounded w-full h-[25px]"
          />
      </div>
      <span className="text-center align-top text-xs font-DINPro font-bold leading-4 whitespace-nowrap text-[#999999] mt-1.5 inline-flex">
          {I18n.t(name)}
      </span>
    </button>

  )
}
