import React from 'react';

import Image from 'next/legacy/image';

import I18n from '@utils/i18n';

export const EOAWallet = ({ image, name, id, onClick }) => {
  return (
    <button onClick={() => onClick(id)} className="bg-[#f6f8fb] rounded-lg flex flex-row justify-center items-center mt-4 max-[600px]:w-full w-[400px] h-[52px] overflow-hidden">
      <div className="flex flex-col justify-center items-center w-[25px] h-fit overflow-hidden ml-3">
          <Image
            src={image}
            alt="kikitrade"
            className="rounded w-full h-[25px]"
          />
      </div>
      <span className="w-[110px] text-center align-top text-lg font-DINPro font-bold leading-[26px] whitespace-nowrap text-black ml-3">
        {I18n.t(name)}
      </span>
    </button>

  )
}
