import React from 'react';

import Image from 'next/legacy/image';

import { openModal } from '@components/Element'
import I18n from '@utils/i18n';

const ModalContent = (props) => {
  const { close, callBack, content } = props
  const onConfim = () => {
    close()
    callBack?.()
  }
  return (
      <div className='flex flex-col items-center p-8 max-[600px]:p-4'>
          <div className='pb-[34px] max-[600px]:w-full'>
            <div className=' flex flex-row justify-between items-center w-[576px] max-[600px]:w-full h-[28px]'>
              <div className='w-[180px] h-[28px] '>
                <span className="text-left align-top text-xl font-DINPro font-bold whitespace-nowrap text-black inline-flex">
                  {I18n.t('tip')}
                </span>
              </div>
              <div className='w-5 h-5'>
                <Image
                  src={require('@images/icon/close.webp')}
                  alt="kikitrade"
                  className="w-5 h-5 cursor-pointer"
                  onClick={close}
                />
              </div>
            </div>
          </div>
          <div className="px-8 py-4 flex flex-row justify-center items-center w-[640px] max-[600px]:w-full max-[600px]:px-0 h-fit">
            <span className="text-center align-top text-sm font-DINPro font-normal text-black/80">
              {
                content
              }
            </span>
          </div>

          <div className='flex flex-row justify-end items-center mt-8 w-full'>
            <button className="btn-secondary w-[180px]" onClick={close}>
              {I18n.t('common_cancel')}
            </button>

            <button className="btn-primary w-[180px] ml-3" onClick={onConfim} >
              {I18n.t('ok')}
            </button>
          </div>
        </div>
  );
}

export const OpenConnectTipModal = (props) => {
  return openModal({
    title: '',
    footer: null,
    closable: false,
    centered: true,
    children: (close) => <ModalContent {...props} close={close}/>
  })
}
