// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { OauthNonceMutationResponse, OauthNonceQueryParams } from "../../models/OauthNonce";


import { baseUrl } from "../../../../axiosHooks";


export function oauthNonceMutationOptions <TData = OauthNonceMutationResponse, TError = unknown>(params: OauthNonceQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = oauthNonceQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/oauth/nonce`,
        params,
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 获取随机数
 * @summary 获取随机数
 * @link /customer/oauth/nonce
 */

export function useOauthNonce <TData = OauthNonceMutationResponse, TError = unknown>(params: OauthNonceQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...oauthNonceMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
