// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { LoginAuthMutationResponse, LoginAuthQueryParams } from "../../models/LoginAuth";


import { baseUrl } from "../../../../axiosHooks";


export function loginAuthMutationOptions <TData = LoginAuthMutationResponse, TError = unknown>(params: LoginAuthQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = loginAuthQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/login/auth`,
        params,
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 通过wallet登陆
 * @summary 通过wallet登陆
 * @link /customer/login/auth
 */

export function useLoginAuth <TData = LoginAuthMutationResponse, TError = unknown>(params: LoginAuthQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...loginAuthMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
