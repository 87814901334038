// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { VerifyMutationResponse, VerifyQueryParams, VerifyHeaderParams } from "../../models/Verify";


import { baseUrl } from "../../../../axiosHooks";


export function verifyMutationOptions <TData = VerifyMutationResponse, TError = unknown>(params: VerifyQueryParams, headers?: VerifyHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = verifyQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/verify`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 账户验证
 * @summary 账户验证
 * @link /customer/verify
 */

export function useVerify <TData = VerifyMutationResponse, TError = unknown>(params: VerifyQueryParams, headers?: VerifyHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...verifyMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
