// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { QuickLoginMutationResponse, QuickLoginQueryParams, QuickLoginHeaderParams } from "../../models/QuickLogin";


import { baseUrl } from "../../../../axiosHooks";


export function quickLoginMutationOptions <TData = QuickLoginMutationResponse, TError = unknown>(params?: QuickLoginQueryParams, headers?: QuickLoginHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = quickLoginQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/login/quick`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @link /customer/login/quick
 */

export function useQuickLogin <TData = QuickLoginMutationResponse, TError = unknown>(params?: QuickLoginQueryParams, headers?: QuickLoginHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...quickLoginMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
