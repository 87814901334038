// @ts-nocheck

import cookie from 'js-cookie';

export const configHeaders = () => {
  // 判断是否为移动端
  const deviceType = global?.device_type;
  const isAppEnv = ['ios', 'android'].includes(deviceType);
  const headers = isAppEnv ? configApp() : configWeb()
  return headers;
}

export const configApp = () => {
  const jwt = global?.jwtKey || '';
  const userId = global?.USER_ID
  const headers = {
    Jwt_token: jwt,
    Authorization: jwt,
    customer_id: userId,
    traffic: global?.traffic,
    trace_id: global?.traceId,
    device_id: global?.DEVICE_ID,
    device_type: global?.device_type,
    device_token: global?.device_token
  }

  return headers;
}

export const configWeb = () => {
  const jwt = cookie.get('jwt') || globalThis?.localStorage?.getItem?.("jwtKey")
  let userId = ''
  try {
    userId = JSON.parse(localStorage?.getItem('userStore'))?.userInfo?.id || localStorage?.getItem?.('userId') || ''
  } catch (e) {
  }

  let traffic = ''
  if (['pre1', 'beta1', 'prodgreen', 'local'].includes(process.env.NEXT_PUBLIC_API_ENV)) {
    traffic = 'green'
  } else {
    traffic = 'blue'
  }
  const headers = {
    Jwt_token: jwt,
    Authorization: jwt,
    device_type: 'web',
    traffic,
    customer_id: userId,
    device_token: (typeof window !== 'undefined' && window.umModule) ? window.umModule.getToken() : '',
  }

  return headers;
}
