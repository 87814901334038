export const EOAWalletData = [
  {
    image: require('@images/web3/metamask.webp'),
    name: 'Metamask',
    id: 'metamask'
  },
  {
    image: require('@images/web3/walletconnect.webp'),
    name: 'WalletConnect',
    id: 'walletconnect_v2'
  }
]

export const OSWalletData = [
  {
    image: require('@images/web3/apple.webp'),
    name: 'Apple',
    id: 'apple'
  },
  {
    image: require('@images/web3/meta.webp'),
    name: 'Facebook',
    id: 'facebook'
  },
  {
    image: require('@images/web3/goog.webp'),
    name: 'Google',
    id: 'google'
  },
  {
    image: require('@images/web3/phone.webp'),
    name: 'mobile',
    id: 'phone'
  },
  {
    image: require('@images/web3/email.webp'),
    name: 'email',
    id: 'email'
  }
]
