import React from 'react';

import { message } from 'antd';
import { ParticleModalProvider, useEOAWalletConnectKit } from 'particle-connect'

import { EOAWallet } from '@/web3/components/wallet/eoa'
import { OpenConnectTipModal } from '@/web3/components/wallet/modal/ConnectTip'
import { OSWallet } from '@/web3/components/wallet/opensocial'
import { ThirdAuthSource } from '@/web3/constants/bind'
import { EOAWalletData, OSWalletData } from '@/web3/constants/particle'
import { useBindAuth } from '@/web3/hooks/bind/useBindAuth'
import { useOSWalletConnect } from '@/web3/hooks/particle/useOSWalletConnect'
import { openModal } from '@components/Element'
import pubsub from '@lib/scripts/pubsub'
import { useParticleConnect } from '@particle-network/connectkit';
/* @ts-ignore */
import { getPublicAddress } from '@particle-network/connectors';
import { QueryClientProvider } from '@tanstack/react-query';
import { I18n } from '@utils/index';

import NavigationBar from './NavigationBar';
import ParticleLogo from './ParticleLogo';

const ModalContent = (props) => {
  const { close, callBack, userInfo } = props
  const { connectOSWallet, getAddress, getChainName, getSignture } = useOSWalletConnect()
  const { bind } = useBindAuth({ getAddress, getChainName, getSignture })
  const { disconnect } = useParticleConnect()
  const { connectEOAWallet } = useEOAWalletConnectKit()

  const onClickEOAWallet = (type) => {
    openModal(async () => {
      try {
        disconnect()
        const provider = await connectEOAWallet({ id: type })
        const publicAddress = await getPublicAddress(provider)

        const { data, error } = await bind({
          publicAddress,
          thirdAuthSource: ThirdAuthSource.EXTERNAL_WALLET,
          loginType: 'eoa',
          provider
        })
        if (error) {
          message.error(I18n.t(error));
        } else {
          pubsub.publish('CONNECT_WALLET')

          close()
          callBack?.(data)
        }
      } catch (e) {
        console.error(e)
      }
    })
  }
  const openModal = (callBack) => {
    OpenConnectTipModal({
      content: I18n.t('hFO7qW5x'),
      callBack
    })
  }
  const onClickOSWallet = (type) => {
    openModal(async () => {
      const ret = await connectOSWallet(type)
      if (ret?.error) {
        return message.error(I18n.t(ret?.error));
      }
      const { data, error } = await bind({
        thirdAuthSource: ThirdAuthSource.EXTERNAL_SOCIAL,
        loginType: 'opensocial'
      })
      if (error) {
        message.error(I18n.t(error));
      } else {
        close()
        callBack?.(data)
      }
    })
  }

  const skipBind = () => {
    close()
    callBack?.(userInfo)
  }

  return (
    <div className='flex flex-col items-center p-8 pt-0 max-[600px]:p-2'>
      <div className='max-[600px]:w-full justify-center items-center'>
        <NavigationBar
          step={1}
          skipBind={skipBind}
        />
      </div>
      <div className='max-w-[400px] mb-[30px] text-center'>
        <span className="text-center align-top text-2xl font-DINPro font-bold text-black ">
          {I18n.t('eZYCHkVp')}
        </span>
      </div>
      <ParticleLogo />
      <div className=" inline-flex flex-col items-center w-fit h-fit max-w-[400px] max-[600px]:w-full">
        <div className="px-6 max-[600px]:px-0 flex flex-row justify-between mt-4 w-[400px] max-[600px]:w-full">
          {
            OSWalletData.map((item) => (
              <OSWallet
                image={item.image}
                name={item.name}
                id={item.id}
                key={item.id}
                onClick={onClickOSWallet}
              />
            ))
          }
        </div>
        <div className="inline-flex flex-row items-center w-fit h-[15px] mt-[52px]">
          <div
            className="bg-[rgba(0,0,0,0.2)] mr-3 w-[30px] h-[0.5px]"
          />
          <span className="text-left align-top text-[12px] whitespace-nowrap text-black/60">
            {I18n.t('5EQmSceI')}
          </span>
          <div
            className="bg-[rgba(0,0,0,0.2)] ml-3 w-[30px] h-[0.5px]"
          />
        </div>

        <div className="flex flex-col items-center w-fit h-fit max-w-[400px] max-[600px]:w-full mt-4 mb-4">
          {
            EOAWalletData.map((item) => (
              <EOAWallet
                image={item.image}
                name={item.name}
                id={item.id}
                key={item.id}
                onClick={onClickEOAWallet}
              />
            ))
          }
        </div>
        <div className="left-[116.50px] top-[552px] inline-flex flex-row w-fit h-fit mb-2">
          <span className="text-left align-top text-xs font-PingFang HK font-normal leading-6 whitespace-nowrap text-[#8e939f]">
            {I18n.t('zIN7pOhO')}
          </span>
        </div>

      </div>

    </div>
  );
}

export const OpenWalletBindModal = (props) => {
  return openModal({
    title: '',
    footer: null,
    closable: false,
    centered: true,
    children: (close) =>
      <QueryClientProvider client={props.queryClient}>
        <ParticleModalProvider>
          <ModalContent {...props} close={close}/>
        </ParticleModalProvider>

      </QueryClientProvider>
  })
}
