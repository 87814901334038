/*
 * @Author: jikang.wu jikang.wu@kikitrade.com
 * @Date: 2024-01-22 11:46:48
 * @LastEditors: jikang.wu jikang.wu@kikitrade.com
 * @LastEditTime: 2024-01-22 15:23:44
 */
// eslint-disable-file no-use-before-define

import React, { useMemo } from 'react';

const StepBar = (props) => {
  const { step = 0, allSteps = 2 } = props;

  const stepsView = useMemo(() => {
    return Array.of(...Array(allSteps).keys()).map((stepItem: number, index: number) => {
      const bgColor = step === index ? 'bg-[#6f40ee]' : 'bg-[#eaeaed]';
      return (
        <div key={index.toString()} className={`${bgColor} rounded-3xl ml-1 w-[30px] h-1`} />
      )
    })
  }, [step]);

  return (
    <div className="flex flex-row justify-center items-center h-fit">
      {stepsView}
    </div>
  );
}

export default StepBar;
