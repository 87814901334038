import React, { useMemo } from 'react';

import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'

import { OpenParticleWalletModal } from '@/web3/components/wallet/modal/ParticleWallet'
import { isNewUser } from '@/web3/utils/user/isNewUser'
import Search from '@components/CoinSearch';
import Icon from '@components/Icon';
import { LOGIN_BEFORE_PATHS } from '@constants/index';
import { Button } from '@element/index'
import I18n from '@utils/i18n';

import { SEARCH_IMG, WALLET_MENU } from '../../const';
import { Down, LanguageSwitch, PopoverBtn, ProfilePopover } from '../index'

import styles from './index.module.less'

export const NavbarWebRight = ({
  type,
  topSearchType,
  searchDrawerVisabledChange,
  theme,
  isLogin,
  avatar
}) => {
  const _isNewUser = useMemo(() => isNewUser(), [])
  return (
    <div className={styles.headerRight}>
      {
        type !== '1' && topSearchType !== '1' && (
          <Button
            className={styles.menuSearch}
            onClick={searchDrawerVisabledChange}
            data-name='kiki-navabar-web-btn-search'
          >
            <Image
                src={SEARCH_IMG[theme]}
                layout="fixed"
                width={24}
                height={24}
                className='image_borderRadius_24'
                alt=''
              />
          </Button>
        )
      }
      {
        type !== '1' && topSearchType === '1' && <Search />
      }
      {
        isLogin && type !== '1'
          ? (
              <>
              {
                _isNewUser
                  ? <button onClick={OpenParticleWalletModal} className="w-[72px] bg-[#f6f8fb] rounded-2xl w-[72px] h-8 flex items-center mr-5">
                    <div className="w-full text-center  text-sm font-PingFang HK font-medium whitespace-nowrap text-[#434b5f]">{I18n.t('wallet1')}</div>
                </button>
                  : <PopoverBtn
                    primaryText={I18n.t('wallet1')}
                    href=''
                    hasArrow={true}
                    btnClassName={cn(styles.grayBtn, 'mr20')}
                    placement='bottomLeft'
                    childrenMenu={WALLET_MENU}
                    popoverWidth={I18n.locale !== 'en' ? '160px' : '200px'}
                    childrenMenuItemClassName={styles.childrenMenuItemClassName}
                  />

              }
              </>
            )
          : (
              <>

              <Button
                  className={cn(styles.grayBtn + ' mr20', styles.login)}
                  data-buried="login"
                  data-name="kiki-navabar-web-btn-login"
                >
                <Link legacyBehavior href={`${LOGIN_BEFORE_PATHS.LOGIN}?loginType=phone`}><a >{I18n.t('login')}</a></Link>
              </Button>
              <Button
                className={cn(styles.blackBtn + ' mr20', styles.register)}
                data-buried="register"
                data-name="kiki-navabar-web-btn-register"
              >
              <Link legacyBehavior href={`${LOGIN_BEFORE_PATHS.LOGIN}?loginType=wallet`}><a >{I18n.t('register')}</a></Link>
            </Button>

              </>
            )
      }
      <PopoverBtn
        primaryText={<Icon name='downloadCloud' style={{ fontSize: '20px' }}/>}
        href=''
        hasArrow={false}
        btnClassName={cn(styles.iconBtn, 'mr20')}
        placement='bottomLeft'
        childrenMenu={[(<Down key="1" />)]}
        popoverWidth='320px'
      />
      <LanguageSwitch />
      <ProfilePopover avatar={avatar} isLogin={isLogin} isJumpLogin={true} />
    </div>
  );
}
