import React from 'react';

import { Drawer } from 'antd';
import cn from 'classnames';
import { inject, observer } from 'mobx-react'
import Image from 'next/legacy/image'
// import helper from '@utils/helper'
import Router from 'next/router';

import { useOSWalletConnect } from '@/web3/hooks/particle/useOSWalletConnect'
import { isEoaWallet } from '@/web3/utils/particle/isEoaWallet'
import Icon from '@components/Icon';
import { Button } from '@element/index'
import defaultAvatar from '@images/avata.webp';
import { useLogout } from '@lib/hooks'
import pubsub from '@lib/scripts/pubsub'
import I18n from '@utils/i18n';
import p from '@utils/Transfrom';
import { KYC_STEP, KYCTag } from '@widget/user/Kyc/KYCTag'

import { getProfileMenu } from '../../const';

import styles from './index.module.less';

type PersonDrawerProps = {
  peosonvisible: boolean;
  setpeosonvisible: (peosonvisible:boolean)=> void;
  userStore: {
    socialUserInfo: {
      avatar: string,
      nickName: string
    }
  };
}

const PersonDrawer: React.FC<PersonDrawerProps> = ({
  peosonvisible,
  setpeosonvisible,
  userStore,
  userStore: { socialUserInfo: { avatar, nickName } }
}) => {
  const { logout } = useLogout({ userStore })
  const { disconnectWallet } = useOSWalletConnect()
  const onClickLogOut = async () => {
    await logout()
    if (isEoaWallet()) {
      pubsub.publish('DISCONNECT_WALLET')
    } else {
      await disconnectWallet()
    }
  }
  const PROFILE_MENU = getProfileMenu()
  return (
    <Drawer width={300} closable={false} placement="right" visible={peosonvisible}>
      <div className={styles.drawerHeader}>
        <Button className={styles.closeBtn} onClick={() => setpeosonvisible(false)}>
          <Image
            src={require('@images/icon/close.webp')}
            layout="fixed"
            width={p(48)}
            height={p(48)}
            alt=''
          />
        </Button>
      </div>
      {/* <div className={styles.listTitle}>
               {nickName}
            </div> */}
      {/* <div className={styles.listtag}>
              <span className={styles.tag} style={{ color: kycResult.color, background: kycResult.bgColor }}>
                <Icon name={kycResult.iconName} className={styles.kycIcon} />
                <span>{kycResult.text}</span>
              </span>
            </div> */}
      <div className={cn(styles.userInfo, styles.h5UserInfo)}>
        <div className={styles.userInfoAvatarContainer}>
          <Image
            src={avatar || defaultAvatar}
            className={styles.userInfoAvatar}
            width={72}
            height={72}
            alt="kikitrade"
          />
        </div>
        <div className={styles.userInfoNameContainer}>
          <div className={styles.userInfoName}>{nickName}</div>
          <div className={styles.userInfoLabel}><KYCTag tagConfigMap={KYC_STEP()} /></div>
        </div>
      </div>

      <div className={`${styles.btnList} ${styles.btnListpadding}`}>
        {
          PROFILE_MENU.map(item => (
            <Button
              className={`${styles.noticeBtn}  ${styles.noticeBtnmargin}`}
              key={item.activekey}
              onClick={() => Router.push(item.href)}
            >
              <div className={styles.iconBtn2}>
                <Icon
                  style={{ fontSize: '20px', color: '#696F7F' }}
                  name={item.iconName}
                />
              </div>
              <span className={styles.btnText}>{I18n.t(item.name)}</span>
            </Button>
          ))
        }
        <Button
          className={cn(styles.noticeBtn, styles.noticeBtnmargin, styles.line, styles.noRadius)}
          onClick={onClickLogOut}
        >
          <div className={styles.iconBtn2}>
            <Icon name='exit' className={cn(styles.icon, styles.logout)} />
          </div>
          <span className={styles.btnText}>{I18n.t('layout_logout')}</span>
        </Button>
      </div>

    </Drawer>
  )
}

export default inject((rootStore:any) => ({
  userStore: rootStore.store.userStore
}))(observer(PersonDrawer))
