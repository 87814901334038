/*
 * @Author: jikang.wu jikang.wu@kikitrade.com
 * @Date: 2024-01-22 11:46:48
 * @LastEditors: jikang.wu jikang.wu@kikitrade.com
 * @LastEditTime: 2024-01-23 10:38:14
 */
// eslint-disable-file no-use-before-define

import React from 'react';

import { I18n } from '@utils/index';

import StepBar from './StepBar';

const NavigationBar = (props) => {
  const { step, skipBind } = props

  const skip = () => {
    // 跳转登陆成功
    skipBind?.()
  }

  return (
    <div className="p-8 flex flex-row justify-center items-center w-full h-[88px]">
      <StepBar step={step} />
      <span
        onClick={skip}
        className=" cursor-pointer absolute right-8 text-left align-top text-sm font-PingFang HK font-medium leading-6 whitespace-nowrap text-[#999999]"
      >
        {I18n.t('rS3ZZoag')}
      </span>
    </div>
  );
}

export default NavigationBar;
