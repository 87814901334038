// @ts-nocheck

import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { GetPayCurrenciesQueryResponse, GetPayCurrenciesHeaderParams } from "../../models/GetPayCurrencies";

import { baseUrl } from "../../../../axiosHooks";

export const getPayCurrenciesQueryKey = () =>     [{url:`/pay/currencies`}] as const;
export function getPayCurrenciesQueryOptions <TData = GetPayCurrenciesQueryResponse, TError = unknown>(headers: GetPayCurrenciesHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = [...getPayCurrenciesQueryKey(), options];

  return {
    queryKey,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `/pay/currencies`,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      }).then(res => res.data);
    },
  };
}

/**
 * @description 获取可一键提币的币种列表
 * @summary 一键提币币种列表
 * @link /pay/currencies
 */

export function useGetPayCurrencies <TData = GetPayCurrenciesQueryResponse, TError = unknown>(headers: GetPayCurrenciesHeaderParams, options: {
          query?: Partial<UseQueryOptions<TData, TError>>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? [...getPayCurrenciesQueryKey(), clientOptions];

  const query = useQuery<TData, TError>({
    ...getPayCurrenciesQueryOptions<TData, TError>(headers, clientOptions),
    ...queryOptions, queryKey
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
}
