// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { EmailBindMutationResponse, EmailBindQueryParams, EmailBindHeaderParams } from "../../models/EmailBind";


import { baseUrl } from "../../../../axiosHooks";


export function emailBindMutationOptions <TData = EmailBindMutationResponse, TError = unknown>(params: EmailBindQueryParams, headers?: EmailBindHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = emailBindQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/bind/email`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 绑定/换绑邮箱
 * @summary 绑定/换绑邮箱
 * @link /customer/bind/email
 */

export function useEmailBind <TData = EmailBindMutationResponse, TError = unknown>(params: EmailBindQueryParams, headers?: EmailBindHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...emailBindMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
