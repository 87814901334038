// @ts-nocheck

import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { CustomerQueryResponse, CustomerHeaderParams } from "../../models/Customer";

import { baseUrl } from "../../../../axiosHooks";

export const customerQueryKey = () =>     [{url:`/customer`}] as const;
export function customerQueryOptions <TData = CustomerQueryResponse, TError = unknown>(headers?: CustomerHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
  const queryKey = [...customerQueryKey(), options];

  return {
    queryKey,
    queryFn: () => {
      
      return client<TData, TError>({
        method: "get",
        url: `/customer`,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      }).then(res => res.data);
    },
  };
}

/**
 * @description 用户信息获取
 * @summary 用户信息获取
 * @link /customer
 */

export function useCustomer <TData = CustomerQueryResponse, TError = unknown>(headers?: CustomerHeaderParams, options: {
          query?: Partial<UseQueryOptions<TData, TError>>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? [...customerQueryKey(), clientOptions];

  const query = useQuery<TData, TError>({
    ...customerQueryOptions<TData, TError>(headers, clientOptions),
    ...queryOptions, queryKey
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
}
