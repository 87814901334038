// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { WithdrawAssetsOnceMutationResponse, WithdrawAssetsOnceQueryParams, WithdrawAssetsOnceHeaderParams } from "../../models/WithdrawAssetsOnce";


import { baseUrl } from "../../../../axiosHooks";


export function withdrawAssetsOnceMutationOptions <TData = WithdrawAssetsOnceMutationResponse, TError = unknown>(params: WithdrawAssetsOnceQueryParams, headers: WithdrawAssetsOnceHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = withdrawAssetsOnceQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/pay/crypto/withdraw/assets`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 进行一键提币
 * @summary 一键提币
 * @link /pay/crypto/withdraw/assets
 */

export function useWithdrawAssetsOnce <TData = WithdrawAssetsOnceMutationResponse, TError = unknown>(params: WithdrawAssetsOnceQueryParams, headers: WithdrawAssetsOnceHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...withdrawAssetsOnceMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
