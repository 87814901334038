import React from 'react';

import Image from 'next/legacy/image'

import { THEME } from '@constants/index';
import { Button } from '@element/index'
import p from '@utils/Transfrom';

import { MENU_IMG } from '../../const'
import MenuDrawer from '../MenuDrawer'
import { DrawerKeyEum } from '../MenuDrawer/const'

import styles from './index.module.less';

type MenuDrawerSwitchProps = {
  isOnlyLogin: boolean;
  visible: boolean;
  keys?: [(keyof typeof DrawerKeyEum)]
  setVisible: (value: boolean) => void;
  theme?: Theme;
  postActiveList?: any[];
  guideist?: any[];
}

export const MenuDrawerSwitch = (props: MenuDrawerSwitchProps) => {
  const { theme = THEME.HIGHLIGHT, ...otherProps } = props;
  const showDrawer = () => {
    otherProps?.setVisible(true);
  };
  return (
    <div className={styles.h5menuSwitch}>
      <Button className={styles.menuBtn} onClick={showDrawer}>
        <Image
          src={MENU_IMG?.[theme]}
          layout="fixed"
          width={p(48)}
          height={p(48)}
          alt=''
        />
      </Button>
      <MenuDrawer
       {...otherProps}
      />
    </div>
  )
}
