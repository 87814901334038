// @ts-nocheck

import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "../../../../axiosHooks";
import type { ResponseConfig } from "../../../../axiosHooks";
import type { BindOauthMutationResponse, BindOauthQueryParams, BindOauthHeaderParams } from "../../models/BindOauth";


import { baseUrl } from "../../../../axiosHooks";


export function bindOauthMutationOptions <TData = BindOauthMutationResponse, TError = unknown>(params: BindOauthQueryParams, headers?: BindOauthHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): UseMutationOptions<TData, TError, void> {
  // const queryKey = bindOauthQueryKey(params);

  return {
    // queryKey,
    mutationFn: (params) => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/customer/bind/oauth`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
        baseURL: baseUrl
      });
    },
  };
}

/**
 * @description 绑定第三方
 * @summary 绑定第三方
 * @link /customer/bind/oauth
 */

export function useBindOauth <TData = BindOauthMutationResponse, TError = unknown>(params: BindOauthQueryParams, headers?: BindOauthHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    ...bindOauthMutationOptions<TData, TError, void>(clientOptions),
    ...mutationOptions
  });
}
